<template>
    <ODropdown>
        <template #default="{target, open}">
            <button :ref="target" class="btn btn-sm btn-link p-0" @click="open" :disabled="isCommitting">
                <i v-if="type == 'save'" class="bi bi-check-lg icon-bold text-primary" :title="$t('Save pasted changes')"></i>
                <i v-else class="bi bi-x-lg text-primary" :title="$t('Cancel pasted changes')"></i>
            </button>
        </template>
        <template #dropdown="{container, close}">
            <div class="dropdown-menu show" :ref="container" style="max-width: 350px">
                <!-- <h6 class="dropdown-header pb-0" :class="`text-${errorType}`">{{ $t('Error') }}</h6> -->
                <!-- <p class="mx-3 my-0 text-wrap" :class="`text-${errorType}`">{{ row.error }}</p> -->
                <!-- <hr class="dropdown-divider"> -->
                <template v-if="type == 'save'">
                    <h6 class="dropdown-header pb-0">{{ $t('Commit All Pasted Rows') }}</h6>
                    <button class="dropdown-item" @click="() => {savePastedRows(false); close()}">
                        {{$t('One By One')}}
                    </button>
                    <button class="dropdown-item" @click="() => {savePastedRows(true); close()}">
                        {{$t('Bulk')}}
                    </button>
                    <hr class="dropdown-divider">
                    <button class="dropdown-item" @click="() => {saveRow(); close()}">
                        {{$t('Save This Row')}}
                    </button>
                </template>
                <template v-else>
                    <button class="dropdown-item" @click="() => {removeRow(true); close()}">
                        {{$t('Clear Pasted Rows')}}
                    </button>
                    <button class="dropdown-item" @click="() => {removeRow(false); close()}">
                        {{$t('Remove This Row')}}
                    </button>
                </template>
            </div>
        </template> 
    </ODropdown>
</template>

<script setup lang="ts">
import type { DataItemModel } from 'o365.modules.DataObject.Types.ts';
import ODropdown from 'o365.vue.components.DropDown.vue';
import { getDataObjectById } from 'o365.vue.ts';
import { defineComponent, computed, ref } from 'vue';

const props = defineProps<{
    row: DataItemModel,
    type: 'save' | 'cancel',
}>();

function saveRow() {
    props.row.disableSaving = false;
    return props.row.save();
}

function removeRow(pAll) {
    if (pAll) {
        const dataObject = getDataObject();
        dataObject.batchData.data.filter(item => item.disableSaving).map(item => {
            return dataObject.batchData.getInversedIndex(item.index);
        }).sort((a, b) => b - a).forEach(index => {
            dataObject.batchData.storage.removeItem(index);
        });
    } else {
        props.row.cancelChanges();
    }
}

function getDataObject() {
    return getDataObjectById(props.row.dataObjectId, props.row.appId);
}

const isCommitting = ref(false);
function savePastedRows(pBulk: boolean) {
    const dataObject = getDataObject();
    const pastedItems = dataObject.batchData.data.filter(item => item.disableSaving);
    isCommitting.value = true;
    if (pBulk) {
        dataObject.recordSource.bulkCreateItems(pastedItems).then(() => {
            pastedItems.forEach(item => item.disableSaving = false);
        }).finally(() => {
            isCommitting.value = false;
        });
    } else {
        pastedItems.forEach(item => item.disableSaving = false);
        dataObject.batchData.saveChanges(undefined, false).catch(ex => {
            pastedItems.forEach(item => {
                if (item.hasChanges) {
                    item.disableSaving = true;
                }
            });
        }).finally(() => {
            isCommitting.value = false;
        });
    }
}


</script>

<script lang="ts">

export default defineComponent({
    inheritAttrs: false
});
</script>